export function customInfoWindowLogic(store) {
  //TODO find more stable solution than a timeout
  setTimeout(() => {
    let infoWindowScrollContainer = document.getElementById("infowindow-horizontal-overflow-container");
    let streetViewBtn = document.getElementById("streetview-btn");
    let closeBtn = document.getElementById("infowindow-close-btn");
    let videoBtn = document.getElementById("infowindow-store-tour-btn");

    let imgArray = [];
    if (store.dealerDetails.images.storeThumbnail) {
      imgArray.push(store.dealerDetails.images.storeThumbnail);
    }
    imgArray.push(...store.dealerDetails.images.outsideViews, ...store.dealerDetails.images.insideViews, ...store.dealerDetails.images.productPlacesViews);
    if (imgArray.length > 0) {
      initInfoWindowImgCarousel(imgArray);
    }

    infoWindowScrollContainer.addEventListener("touchstart", (e) => {
      e.stopPropagation();
    });
    infoWindowScrollContainer.addEventListener("scroll", (e) => {
      e.stopPropagation();
    });
    closeBtn.addEventListener("click", () => {
      window.vueApp.$children[0].$children[0].deHighlightStore();
      window.vueApp.$children[0].$children[0].$children[0].infoWindow.close();
    });
    videoBtn.addEventListener("click", () => {
      callStoreTourModal("test", "https://static.redseafish.com/wp-content/uploads/2024/10/29132739/RD-loop-16-MOB-E.mp4");
    });
    streetViewBtn.addEventListener("click", () => {
      window.vueApp.$children[0].$children[0].$children[0].addStreetViewPanorama({
        lat: Number(streetViewBtn.getAttribute("data-lat")),
        lng: Number(streetViewBtn.getAttribute("data-lng")),
      });
      window.vueApp.$children[0].$children[0].isStreetView = true;
    });
  }, 120);
}

function callStoreTourModal(modalId, videoSrc) {
  const el = document.getElementById(`rsf-video-modal-${modalId}`);

  if (el) return;

  if (modalId.includes("youtube")) {
    modalId = getmodalIdFromUrl(modalId);
  }

  const modal = `
    <div id="rsf-video-modal-${modalId}" class="rsf_video_modal stb_modal border-box px2">
        <div onclick="event.stopPropagation()" class="content-wrapper col-12 relative border-box w-fit-content overflow-hidden" style="border-radius: 8px; max-height: 69dvh">
            <button id="rsf-video-modal-close-btn-${modalId}" class="rsf-video-modal-close-btn pointer bg-color-6" style="position: absolute; top: .5rem; right: .5rem; width: 2rem; height: 2rem; border-radius: 999px; z-index: 3;">
                <svg class="svg-color-white" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_326_9822)">
                        <rect width="19.6867" height="2" rx="1" transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.154785 1.58563)" fill="black" />
                        <rect x="15.4895" y="1.58575" width="19.6867" height="2" rx="1" transform="rotate(135 15.4895 1.58575)" fill="black" />
                    </g>
                    <defs>
                        <clipPath id="clip0_326_9822">
                            <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 16 0)" />
                        </clipPath>
                    </defs>
                </svg>
            </button>
            <div class="">
                <video class="h100 bg-color-black" style="aspect-ratio: 9/16; object-fit: contain; max-height: 70dvh" controls alt="Store tour video" src="${videoSrc}"></video>
            </div>
        </div>
    </div>`;

  document.body.insertAdjacentHTML("beforeend", modal);

  const appendedModal = document.getElementById(`rsf-video-modal-${modalId}`);
  const modalCloseBtn = document.getElementById(`rsf-video-modal-close-btn-${modalId}`);

  setTimeout(() => {
    appendedModal.classList.add("stb_modal_active");
  }, 100);

  appendedModal.addEventListener("click", () => {
    closeVideoModal(`${modalId}`);
  });
  modalCloseBtn.addEventListener("click", () => {
    closeVideoModal(`${modalId}`);
  });
}

function closeVideoModal(modalId) {
  const modal = document.getElementById(`rsf-video-modal-${modalId}`);
  modal.classList.toggle("stb_modal_active");
  setTimeout(() => {
    modal.remove();
  }, 200);
}

function initInfoWindowImgCarousel(imgArray) {
  let carousel = document.getElementById("infowindow-carousel");
  let tabHolder = document.getElementById("infowindow-carousel-tab-holder");
  carousel.style.display = "block";
  for (let i = 0; i < imgArray.length; i++) {
    let constructedImg = constructImg(imgArray[i]);
    let tab = constructTab(i);
    if (i == 0) {
      tab.classList.add("active");
    }
    if (i > 0) {
      constructedImg.classList.add("op0");
    }
    carousel.appendChild(constructedImg);
    if (imgArray.length > 1) {
      tabHolder.appendChild(tab);
      tab.addEventListener("click", () => {
        setInfowindowCarouselSlide(carousel, i);
      });
    }
  }

  carousel.dataset.maxSlides;
}
function constructTab(i) {
  let div = document.createElement("div");
  div.className = "infowindow-carousel-tab";
  div.dataset.slide = i;
  let innerDiv = document.createElement("div");
  div.appendChild(innerDiv);
  return div;
}
function constructImg(src) {
  let img = document.createElement("img");
  img.src = src;
  img.alt = "Store image";
  img.style.position = "absolute";
  img.style.inset = "0";
  img.style.objectFit = "cover";
  img.style.borderTopLeftRadius = "8px";
  img.style.borderTopRightRadius = "8px";
  img.className = "cover col-12 h100";
  return img;
}

function setInfowindowCarouselSlide(carousel, slide) {
  let imgs = carousel.querySelectorAll("img");
  let tabs = carousel.querySelectorAll(".infowindow-carousel-tab");
  for (let i = 0; i < imgs.length; i++) {
    if (i == slide) {
      imgs[i].classList.remove("op0");
    } else {
      imgs[i].classList.add("op0");
    }
  }

  for (let i = 0; i < tabs.length; i++) {
    if (i == slide) {
      tabs[i].classList.add("active");
    } else {
      tabs[i].classList.remove("active");
    }
  }
}
