// src/lang/index.js
import de from "./translations/de";
import en from "./translations/en";
import fr from "./translations/fr";
import ja from "./translations/ja";
export default {
  en,
  de,
  fr,
  ja,
};
