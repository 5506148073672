import Storefinder from "./components/Storefinder.vue";
import Test from "./components/Test.vue";

export default [
  {
    path: "/:lang",
    component: Storefinder,
  },
  {
    path: "/:lang/debug",
    component: Test,
  },
  {
    path: "*",
    redirect: "/en",
  },
];
