<template>
  <div style="margin-top: 30vh" ref="test-portal"></div>
</template>

<script>
import { customInfoWindowConstruction } from "@/assets/scripts/customInfoWindow";
import { customInfoWindowLogic } from "@/assets/scripts/customInfoWindowLogic";
export default {
  name: "Test",
  mounted() {
    let sampleStore = {
      store: {
        position: {
          lat: 53.58262,
          lng: 7.897697,
        },
        store: {
          id: "76",
          name: "Testing store",
          mapCoordsLatitude: "53.582620",
          mapCoordsLongitude: "7.897697",
          distance: 0.8012958815130068,
          dealerDetails: {
            dealerName: "Testing store",
            dealerCountryCode: "DE",
            dealerClientLanguage: "en",
            images: {
              storeLogo: "",
              storeThumbnail: "https://static.redseafish.com/static-content/dealer-20240812-c5f990af75341aca46ccf7ccaa28bcba.jpg",
              insideViews: [],
              outsideViews: [],
            },
            address1: "test 1",
            address2: " ",
            city: "Berlin",
            state: " ",
            postal: "93612",
            phoneNumber: " ",
            websiteURL: "",
            openingTimes: "",
            productTags: "",
            dealerDescription: "",
          },
          distanceImperial: 0.4979020231916185,
        },
      },
      userPosition: {
        lat: 53.57557869999999,
        lng: 7.9002789,
        zoom: 15,
      },
      unit: "metric",
      translations: {
        storefinder_btn_label_email: this.$t("storefinder_btn_label_email"),
        storefinder_btn_label_website: this.$t("storefinder_btn_label_website"),
        storefinder_btn_label_phone: this.$t("storefinder_btn_label_phone"),
        storefinder_btn_label_get_directions: this.$t("storefinder_btn_label_get_directions"),
        storefinder_btn_label_street_view: this.$t("storefinder_btn_label_street_view"),
        storefinder_store_away: this.$t("storefinder_store_away"),
        storefinder_unit_kilometers: this.$t("storefinder_unit_kilometers"),
        storefinder_unit_miles: this.$t("storefinder_unit_miles"),
        storefinder_btn_label_video: this.$t("storefinder_btn_label_video"),
      },
    };
    console.log(sampleStore.store.store.dealerDetails.images);
    this.$refs["test-portal"].innerHTML = customInfoWindowConstruction(sampleStore.store.store, sampleStore.userPosition, sampleStore.unit, sampleStore.translations);
    customInfoWindowLogic();
  },
};
</script>

<style scoped>
.overflow-y-scroll {
  overflow-y: auto;
}
</style>
